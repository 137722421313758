<template>
  <div class="forge-vuer-container">
    <div :id="id" class="forge-vuer-viewer-display" @resize="handleResize" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "ForgeVuer",
  props: {
    id: {
      type: String,
      default: () => {
        return `forge-vuer-${Math.random().toString(36).substring(8)}`;
      },
    },
    getAccessToken: {
      type: Function,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    urn: {
      type: Array,
    },
    version: {
      type: String,
      default: () => "7.*",
    },
    autodeskViewingOptions: {
      type: Object,
      default: () => ({}),
    },
    aggregateViewOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    scriptLoaded: false,
    initialized: false,
  }),
  computed: {},
  watch: {
    urn: {
      immediate: false,
      deep: false,
      handler(urn) {
        if (!urn || urn.length === 0 || !this.initialized) return;

        if (this.aggregatedView) {
          this.aggregatedView.unloadAll();
        }
        const tasks = urn.map((element) => this.loadDocument(element));
        Promise.all(tasks)
          .then((models) => {
            const bubbles = models.map((model) => this.getBubble(model));
            this.setModels(bubbles);
          })
          .catch((error) => {
            console.error(error);
          });
      },
    },
  },
  async mounted() {
    if (!window.Autodesk) {
      await this.loadStyle(
        `https://developer.api.autodesk.com/modelderivative/v2/viewers/${this.version}/style.min.css`
      );
      await this.loadScript(
        `https://developer.api.autodesk.com/modelderivative/v2/viewers/${this.version}/viewer3D.min.js`
      );
    }

    if (typeof this.getAccessToken !== "function")
      throw new Error(
        `The 'getAccessToken' prop needs to be a function implementing a callback passing in the generated token and expire timeout in seconds.`
      );

    const interval = setInterval(() => {
      if (this.urn.length === 0) return;
      clearInterval(interval);

      this.initialize(this.getAccessToken, this.autodeskViewingOptions)
        .then(() => {
          const container = document.getElementById(this.id);
          return this.launchViewer(container, this.aggregateViewOptions);
        })
        .then(() => {
          this.changeSpinner();

          const tasks = this.urn.map((element) => this.loadDocument(element));
          return Promise.all(tasks);
        })
        .then((models) => {
          const bubbles = models.map((model) => this.getBubble(model));
          this.setModels(bubbles);
        })
        .then(() => {
          this.initialized = true;
        })
        .catch((error) => {
          console.error(error);
        });
    }, 100);
  },
  beforeDestroy() {
    try {
      this.aggregatedView.viewer.finish();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    loadScript(url) {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = url;
        script.addEventListener("load", () => resolve(true));
        document.head.appendChild(script);
      });
    },
    loadStyle(url) {
      return new Promise((resolve) => {
        const css = document.createElement("link");
        css.rel = "stylesheet";
        css.href = url;
        css.type = "text/css";
        css.addEventListener("load", () => resolve(true));
        document.head.appendChild(css);
      });
    },
    initialize(getAccessToken, options) {
      const viewerOptions = Object.assign({}, options, { getAccessToken });

      return new Promise((resolve, reject) => {
        try {
          window.Autodesk.Viewing.Initializer(viewerOptions, () => {
            this.$emit("autodesk-initialized", true);
            resolve(true);
          });
        } catch (error) {
          this.$emit("autodesk-initialize-failed", error);
          reject(error);
        }
      });
    },
    launchViewer(containerId, options3d) {
      if (this.aggregatedView) return this.aggregatedView;

      return new Promise((resolve, reject) => {
        try {
          this.aggregatedView = new window.Autodesk.Viewing.AggregatedView();
          this.aggregatedView.init(containerId, options3d).then(() => {
            this.$emit("viewer-started", this.aggregatedView);
            resolve(this.aggregatedView);
          });
        } catch (error) {
          this.$emit("viewer-init-failed", error);
          reject(error);
        }
      });
    },
    loadDocument(model) {
      return new Promise((resolve, reject) => {
        const documentId = this.encodeURN(model.urn);

        const onDocumentLoadSuccess = (document) => {
          model = Object.assign(model, { document });
          this.$emit("document-loaded", model);
          resolve(model);
        };

        const onDocumentLoadError = (error) => {
          this.$emit("document-load-failed", error);
          reject(error);
        };

        window.Autodesk.Viewing.Document.load(
          documentId,
          onDocumentLoadSuccess,
          onDocumentLoadError
        );
      });
    },
    getBubble(model) {
      if (!model.viewable || model.viewable.length === 0) {
        return model.document.getRoot().getDefaultGeometry();
      }

      if (model.viewable && Array.isArray(model.viewable)) {
        return model.viewable
          .map(
            (element) =>
              model.document.getRoot().search({ guid: element.guid })[0]
          )
          .flat();
      }

      return model.document.getRoot().search({ guid: model.viewable.guid })[0];
    },
    setModels(bubbles) {
      this.aggregatedView
        .setNodes(bubbles.flat())
        .then((loadedModels) => {
          this.$emit("model-loaded", loadedModels);
        })
        .catch((error) => {
          this.$emit("model-load-failed", error);
        });
    },
    encodeURN(urn) {
      if (urn.includes("http")) return urn;

      return urn.includes("adsk")
        ? `urn:${btoa(urn)}`
        : !urn.includes("urn")
        ? `urn:${urn}`
        : urn;
    },
    changeSpinner() {
      // logo and name
      document.querySelector(
        ".forge-spinner > img"
      ).src = `${process.env.VUE_APP_CDN_BASE_URL}/shared/resource/logo/syncobox-logo.svg`;
      document.querySelector(".forge-spinner > img").width = "650";

      // spinner
      document.querySelector(".forge-spinner > svg").outerHTML = `
        <svg width="240" height="240" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(2 1)" stroke="#006ad8" stroke-width="1.5">
              <circle cx="42.601" cy="11.462" r="5" fill-opacity="1" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="1;0;0;0;0;0;0;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="49.063" cy="27.063" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;1;0;0;0;0;0;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="42.601" cy="42.663" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;0;1;0;0;0;0;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="27" cy="49.125" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;0;0;1;0;0;0;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="11.399" cy="42.663" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;0;0;0;1;0;0;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="4.938" cy="27.063" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;0;0;0;0;1;0;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="11.399" cy="11.462" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;0;0;0;0;0;1;0" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
              <circle cx="27" cy="5" r="5" fill-opacity="0" fill="#006ad8">
                <animate attributeName="fill-opacity"
                  begin="0s" dur="1.3s"
                  values="0;0;0;0;0;0;0;1" calcMode="linear"
                  repeatCount="indefinite" />
              </circle>
            </g>
          </g>
        </svg>`;
      document.querySelector(".forge-spinner > svg").style.transform =
        "rotate(0deg)";
      document.querySelector(".forge-spinner > svg").style.width = "320px";
    },
    handleResize() {
      if (this.aggregatedView.viewer) this.aggregatedView.viewer.resize();
    },
  },
};
</script>

<style lang="scss" scoped>
.forge-vuer-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.forge-vuer-viewer-display {
  height: 100%;
  width: 100%;
}
.forge-vuer-markup-editor {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
