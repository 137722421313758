import Vue from "vue";
import VueRouter from "vue-router";
// import ConfiguratorPage from "../views/ConfiguratorPage.vue";
import NotFound from "../views/NotFound.vue";
import Main from "../views/Main.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/error404",
    name: "error404",
    component: NotFound,
  },
  {
    path: "/v2/:shareCode",
    name: "V2Configurator",
    component: Main,
    props: (route) => ({
      defaultViewer3d: route.query.defaultViewer3d === "true",
      formLeft: route.query.formLeft === "true",
      formWidth: route.query.formWidth,
      readOnly: route.query.readOnly === "true",
      verbose: +route.query.verbose,
      defaultAutoUpdate: route.query.defaultAutoUpdate !== "false",
      defaultForm: route.query.defaultForm !== "false",
      roomId: route.query.roomId,
      restoreImmediately: route.query.restoreImmediately === "true",
      eventTriggered: route.query.eventTriggered === "true",
      latency: +route.query.latency,
      restoreFPS: +route.query.restoreFPS,
    }),
  },
  {
    path: "/:shareCode",
    name: "V1Configurator",
    component: Main,
    props: (route) => ({
      defaultViewer3d: route.query.model3d === "true",
      formLeft: route.query.navPos === "left",
      formWidth: Math.max(
        widthAdapter(route.query.navSideWidth),
        widthAdapter(route.query.navCenterWidth)
      ),
      readOnly: route.query.readOnly === "true",
      verbose: route.query.verbose ? +route.query.verbose : undefined,
      defaultAutoUpdate: route.query.defaultAutoUpdate !== "false",
      defaultForm: route.query.defaultOpenForm !== "false",
      roomId: route.query.roomId,
      restoreImmediately: route.query.restoreImmediately === "true",
      eventTriggered: route.query.eventTriggered === "true",
      latency: route.query.latency ? +route.query.latency : undefined,
      restoreFPS: route.query.restoreFPS ? +route.query.restoreFPS : undefined,
    }),
  },
  {
    path: "*",
    redirect: "/error404",
  },
];

function widthAdapter(input) {
  if (input && input.includes("%"))
    return Math.round((12 * Number(input.replace("%", ""))) / 100);
  else if (input && input.includes("px"))
    return Math.round(
      (Number(input.replace("px", "")) / window.innerWidth) * 12
    );
  else return 4;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
