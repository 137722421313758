<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App',
  beforeCreate () {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }
      t = l.createElement(r)
      t.async = 1
      t.src = 'https://www.clarity.ms/tag/' + i
      y = l.getElementsByTagName(r)[0]
      y.parentNode.insertBefore(t, y)
    })(window, document, 'clarity', 'script', process.env.VUE_APP_CLARITY_TRACKING_CODE)
  }
}
</script>

<style>

html {
  overflow: hidden !important;
}

html, body, #app {
  height: 100%;
}

.v-application--wrap {
  min-height: 100% !important;
}

</style>
