var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto overflow-hidden",attrs:{"height":"100%","width":"100%","flat":"","tile":""}},[_c('v-container',{staticClass:"pa-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{class:{ 'flex-row': !_vm.formLeft, 'flex-row-reverse': _vm.formLeft },staticStyle:{"height":"100%"},attrs:{"justify":"center","no-gutters":""}},[_c('v-fade-transition',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showForge),expression:"showForge"}],staticStyle:{"height":"100%"},attrs:{"cols":_vm.forgeWidth}},[_c('Forge',{attrs:{"getAccessToken":_vm.token(),"urn":_vm.getUrn,"loading":_vm.loading},on:{"viewer-started":_vm.onViewerStart}})],1)],1),_c('v-fade-transition',[(_vm.showForm)?_c('v-col',{staticStyle:{"height":"100%"},attrs:{"cols":_vm.formWidth}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[(_vm.showForge)?_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
                  _vm.showForm = false;
                }}},[_c('v-icon',[_vm._v(_vm._s(_vm.formLeft ? "mdi-arrow-left" : "mdi-arrow-right")+" ")])],1):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.showForge ? 'success' : 'gray',"icon":""},on:{"click":() => {
                      _vm.showForge = !_vm.showForge;
                    }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-3d")])],1)]}}],null,false,3481838586)},[_c('span',[_vm._v(_vm._s(_vm.$t("model") + _vm.$t("already") + (_vm.showForge ? _vm.$t("open") : _vm.$t("closeDown"))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.autoUpdate ? 'success' : 'gray',"icon":""},on:{"click":() => {
                      _vm.autoUpdate = !_vm.autoUpdate;
                    }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.autoUpdate ? "mdi-restart" : "mdi-restart-off")+" ")])],1)]}}],null,false,872622223)},[_c('span',[_vm._v(_vm._s(_vm.$t("auto") + _vm.$t("update") + _vm.$t("already") + (_vm.autoUpdate ? _vm.$t("open") : _vm.$t("closeDown"))))])])],1),_c('Form',{staticClass:"pa-0",staticStyle:{"height":"calc(100% - 48px)"},attrs:{"formSchema":_vm.formComponent,"readOnly":_vm.readOnly,"onlyInput":true,"loading":_vm.loading,"autoUpdate":_vm.autoUpdate},on:{"submit":_vm.onFormSubmit}})],1):_vm._e()],1)],1)],1),(!_vm.showForm)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"transform":"translate(-50%, -110%)"},attrs:{"absolute":"","color":"primary","fab":"","large":"","dark":"","bottom":"","right":""},on:{"click":() => {
            _vm.showForm = true;
          }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-list-box-outline")])],1)]}}],null,false,720019911)},[_c('span',[_vm._v(_vm._s(_vm.$t("open") + _vm.$t("form")))])]):_vm._e(),_c('v-dialog',{attrs:{"width":"400","z-index":"20"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('perfect-scrollbar',{staticStyle:{"max-height":"calc(80vh - 52px)"}},[_c('v-card-text',{staticClass:"pt-5 pb-2 text-body-1"},_vm._l((_vm.errorMessages),function(msg,index){return _c('p',{key:index},[_vm._v(_vm._s(msg))])}),0)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":() => (_vm.error = false)}},[_vm._v(_vm._s(_vm.$t("closeDown"))+" ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"width":"400","z-index":"20","persistent":""},model:{value:(_vm.waiting),callback:function ($$v) {_vm.waiting=$$v},expression:"waiting"}},[_c('v-card',{staticClass:"pa-2 d-flex flex-column justify-center align-center",attrs:{"tile":"","flat":""}},[_c('h4',{staticClass:"text-center my-2"},[_vm._v("商品產生中")]),_c('v-progress-linear',{staticClass:"my-2",attrs:{"buffer-value":"0","stream":"","persistent":"","indeterminate":"","color":"primary"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }