import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import syncoboxfomio from "@syncobox/syncobox-formio";
import "@syncobox/syncobox-formio/dist/syncobox-formio.css";
import i18n from "./i18n";

Vue.config.productionTip = false;

import API from '@syncobox/syncobox-shared-api'
API.config.mainBaseUrl = process.env.VUE_APP_MAIN_BASE_URL;
API.config.bimBaseUrl = process.env.VUE_APP_BIM_BASE_URL;
API.config.formBaseUrl = process.env.VUE_APP_FORM_BASE_URL;
API.config.docBaseUrl = process.env.VUE_APP_DOC_BASE_URL;
API.config.pcBaseUrl = process.env.VUE_APP_PC_BASE_URL;
API.config.flowBaseUrl = process.env.VUE_APP_FLOW_BASE_URL;
API.config.operationBaseUrl = process.env.VUE_APP_OPERATION_BASE_URL;
API.config.reportingBaseUrl = process.env.VUE_APP_REPORTING_BASE_URL;
API.config.responseErrorHandler = async (error) => {
  if (vm.$API.config.doesUseDefaultErrorHandler) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {

      switch (error.response.status) {
        case 400:
          // console.log("Bad Request")
          // go to 400 page
          // eslint-disable-next-line no-case-declarations
          const message = error && error.response ? error.response.data : JSON.stringify(error);
          await vm.$store.dispatch('notification/warn', `${message}`)
          break
        case 401:
          // console.log("你沒有權限")
          // go to 401 page
          // if (options.oidc && options.oidc !== false) {
          //     try {
          //         await  vm.$store.dispatch('oidcStore/authenticateOidcSilent')
          //     } catch (err) {
          //         console.error(err)
          await vm.$store.dispatch('oidcStore/authenticateOidc')
          //     }
          // }
          break
        case 403:
          // go to 403 page
          // console.warn("你未有足夠存取該資源的權限", error.config.baseURL)
          //error.response.status
          await vm.$store.dispatch('notification/warn', `${JSON.stringify(error)}`)
          break
        case 404:
          // console.log("你要找的頁面不存在")
          await vm.$store.dispatch('notification/warn', `${JSON.stringify(error)}`)
          // go to 404 page
          break
        case 500:
          // console.log("程式發生問題")
          await vm.$store.dispatch('notification/error', `${JSON.stringify(error)}`)
          // go to 500 page
          break
        default:
          // console.log(error.message)
          if (error.response.status >= 400 && error.response.status < 500) {
            await vm.$store.dispatch('notification/warn', JSON.stringify(error))
          } else if (error.response.status >= 500 && error.response.status < 600) {
            await vm.$store.dispatch('notification/error', JSON.stringify(error))
          } else {
            console.error(error.message)
          }
      }
    }
    if (!window.navigator.onLine) {
      alert("網路出了點問題，請重新連線後重整網頁");
      await vm.$store.dispatch('notification/error', error)
      return;
    }
  }
  return Promise.reject(error);
}
Vue.prototype.$API = API

// form io module
Vue.use(syncoboxfomio, {store});


import syncoboxBIMViewer from "@syncobox/syncobox-bim-viewer";

Vue.use(syncoboxBIMViewer);

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

vm.$API = API
