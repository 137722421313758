<template>
  <v-card
    class="pa-0"
    width="100%"
    height="100%"
    flat
    tile
    :loading="loading"
    :disabled="loading"
  >
    <perfect-scrollbar :style="{ height: formHeight }">
      <sb-formio-render
        v-if="formSchema.length > 0"
        ref="formioRender"
        dataType="formComponents"
        :form-components="formSchema"
        :check-input-value="onlyInput"
        :read-only="readOnly"
        @change="onChange"
        @submit="onSubmit"
        @blur="onBlur"
        @focus="onFocus"
    /></perfect-scrollbar>
    <v-card-actions>
      <v-btn
        v-if="!autoUpdate"
        color="primary"
        :loading="loading"
        block
        @click="() => onSubmit(formValue)"
      >
        {{ $t("submit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Form",
  props: {
    formSchema: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    onlyInput: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    autoUpdate: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    focus: false,
    formValue: null,
  }),
  computed: {
    formHeight() {
      return this.autoUpdate ? "100%" : "calc(100% - 52px)";
    },
  },
  methods: {
    onChange(value) {
      console.log("changed", value);
      this.formValue = value;
      if (this.autoUpdate && !this.focus && value) {
        this.onSubmit(value);
      }
    },
    onSubmit(value) {
      console.log("submit", value);
      this.formValue = value;
      this.$emit("submit", value);
    },
    onBlur() {
      this.focus = false;
      this.onChange(this.formValue);
    },
    onFocus() {
      this.focus = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.ps > .container {
  padding: 0 !important;
}
</style>
