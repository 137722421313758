<template>
  <v-card class="mx-auto overflow-hidden" height="100%" width="100%" flat tile>
    <v-container fluid class="pa-0" style="height: 100%">
      <v-row
        :class="{ 'flex-row': !formLeft, 'flex-row-reverse': formLeft }"
        justify="center"
        no-gutters
        style="height: 100%"
      >
        <v-fade-transition>
          <v-col v-show="showForge" :cols="forgeWidth" style="height: 100%">
            <Forge
              :getAccessToken="token()"
              :urn="getUrn"
              :loading="loading"
              @viewer-started="onViewerStart"
            ></Forge>
          </v-col>
        </v-fade-transition>
        <v-fade-transition>
          <v-col v-if="showForm" :cols="formWidth" style="height: 100%">
            <v-toolbar flat dense>
              <v-btn
                v-if="showForge"
                icon
                @click="
                  () => {
                    showForm = false;
                  }
                "
              >
                <v-icon>{{
                    formLeft ? "mdi-arrow-left" : "mdi-arrow-right"
                  }}
                </v-icon>
              </v-btn>

              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="showForge ? 'success' : 'gray'"
                    icon
                    @click="
                      () => {
                        showForge = !showForge;
                      }
                    "
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-rotate-3d</v-icon>
                  </v-btn>
                </template>
                <span>{{
                    $t("model") +
                    $t("already") +
                    (showForge ? $t("open") : $t("closeDown"))
                  }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="autoUpdate ? 'success' : 'gray'"
                    icon
                    @click="
                      () => {
                        autoUpdate = !autoUpdate;
                      }
                    "
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>{{
                        autoUpdate ? "mdi-restart" : "mdi-restart-off"
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{
                    $t("auto") +
                    $t("update") +
                    $t("already") +
                    (autoUpdate ? $t("open") : $t("closeDown"))
                  }}</span>
              </v-tooltip>
            </v-toolbar>
            <Form
              class="pa-0"
              :formSchema="formComponent"
              :readOnly="readOnly"
              :onlyInput="true"
              :loading="loading"
              :autoUpdate="autoUpdate"
              @submit="onFormSubmit"
              style="height: calc(100% - 48px)"
            ></Form>
          </v-col>
        </v-fade-transition>
      </v-row>
    </v-container>

    <v-tooltip top v-if="!showForm">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          absolute
          color="primary"
          fab
          large
          dark
          bottom
          right
          style="transform: translate(-50%, -110%)"
          @click="
            () => {
              showForm = true;
            }
          "
          v-on="on"
          v-bind="attrs"
        >
          <v-icon>mdi-list-box-outline</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("open") + $t("form") }}</span>
    </v-tooltip>

    <v-dialog v-model="error" width="400" z-index="20">
      <v-card tile flat>
        <perfect-scrollbar style="max-height: calc(80vh - 52px)">
          <v-card-text class="pt-5 pb-2 text-body-1">
            <p v-for="(msg, index) in errorMessages" :key="index">{{ msg }}</p>
          </v-card-text>
        </perfect-scrollbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="() => (error = false)">{{
              $t("closeDown")
            }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="waiting" width="400" z-index="20" persistent>
      <v-card
        class="pa-2 d-flex flex-column justify-center align-center"
        tile
        flat
      >
        <h4 class="text-center my-2">商品產生中</h4>

        <v-progress-linear
          class="my-2"
          buffer-value="0"
          stream
          persistent
          indeterminate
          color="primary"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import Form from "../components/Form.vue";
import Forge from "../components/ModelViewer.vue";

const signalR = require("@microsoft/signalr");

export default {
  name: "Main",
  components: {Form, Forge},
  props: {
    defaultViewer3d: {
      type: Boolean,
      default: () => false,
    },
    defaultForm: {
      type: Boolean,
      default: () => true,
    },
    formLeft: {
      type: Boolean,
      default: () => false,
    },
    formWidth: {
      type: Number,
      default: () => 4,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    verbose: {
      type: Number,
      default: 3,
    },
    defaultAutoUpdate: {
      type: Boolean,
      default: () => true,
    },

    // ---------- Sync Extension-----------

    roomId: {
      type: String,
      default: () => null,
    },
    restoreImmediately: {
      type: Boolean,
      default: () => true,
    },
    eventTriggered: {
      type: Boolean,
      default: () => false,
    },
    latency: {
      type: Number,
      default: () => 200,
    },
    restoreFPS: {
      type: Number,
      default: () => 100,
    },
  },
  data: () => ({
    // ----- SignalR -----
    connection: null,
    connectionId: null,

    // ----- settings -----
    showForge: false,
    showForm: true,
    autoUpdate: false,
    forgeActivated: false,

    // ----- utils -----
    shareCode: "",
    loading: false,
    formComponent: [],
    formResult: null,
    urn: [],
    submitHistroy: [],
    metadataTimeout: null,

    // ----- error -----
    error: false,
    errorMessages: [],

    waiting: false,
  }),
  computed: {
    forgeWidth() {
      return this.showForm ? 12 - this.formWidth : 12;
    },
    getUrn() {
      return this.forgeActivated ? this.urn : [];
    },
  },
  created() {
    this.shareCode = this.$route.params.shareCode;
    this.autoUpdate = this.defaultAutoUpdate;
    this.showForge = this.defaultViewer3d;
    this.showForm = this.defaultForm;

    this.getMetaData(this.shareCode);
    this.initSignalR();
  },
  methods: {
    // ----- SignalR -----

    initSignalR() {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_PC_BASE_URL}/publicpchub`)
        .configureLogging(signalR.LogLevel.Information)
        .build();

      this.connection.on("RealtimeProcessingFinish", (connectionId) => {
        console.log("[SignalR] Realtime processing finished", connectionId);
        this.stopWaiting();
        this.getMetaData(this.shareCode, this.formResult);
      });

      this.connection.on("RealtimeProcessingFailed", (connectionId) => {
        console.log("[SignalR] Realtime processing failed", connectionId);
        this.stopWaiting();
        this.showError(["該規格品目前無法使用，請聯絡系統管理員。"]);
      });

      this.connection.onclose(this.startSignalR);
      console.log("[SignalR] Initializing...");
    },

    startSignalR() {
      return new Promise((resolve, reject) => {
        this.connection
          .start()
          .then(() => {
            console.log("[SignalR] Starting...");
            this.connection.invoke("getConnectionId").then((id) => {
              this.connectionId = id;
              resolve(id);
            });
          })
          .catch(reject);
      });
    },

    getConnectionId() {
      return new Promise((resolve) => {
        if (this.connectionId) resolve(this.connectionId);
        this.startSignalR().then(resolve);
      });
    },

    // ----- MetaData -----

    token() {
      return function (onSuccess) {
        axios
          .get(`${process.env.VUE_APP_PC_BASE_URL}/ForgeToken/Public`)
          .then((res) => res.data)
          .then((res) => onSuccess(res.access_token, res.expires_in));
      };
    },

    getMetaData(modelId, formResult = null) {
      this.loading = true;
      clearTimeout(this.metadataTimeout);

      if (formResult) {
        axios
          .post(
            `${process.env.VUE_APP_PC_BASE_URL}/Share/${modelId}/GetPCByParams`,
            formResult
          )
          .then(this.processMetaData)
          .catch((error) =>
            this.showError([`[ ERROR ] ${error.response.data}`])
          );
      } else {
        axios
          .get(`${process.env.VUE_APP_PC_BASE_URL}/Share/${modelId}/Public`)
          .then(this.processMetaData)
          .catch(
            (error) =>
              error.response.status === 404
                ? this.$router.push({path: "error404"})
                : this.showError([`[ ERROR ] ${error.response.data}`])
            // console.error(error)
          );
      }
    },

    processMetaData(res) {
      // messages from Inventor
      if (res.data.messages && res.data.messages.length > 0) {
        const msg = res.data.messages
          .filter((err) => err.isTriggered && err.severity >= this.verbose)
          .map((err) => `[ ERROR ] ${err.fieldName} -  ${err.text}`);
        this.showError(msg);

        this.loading = false;
        return;
      }

      // change document title
      document.title = `產品 (${res.data.name || "無產品名稱"})`;

      // add the request to the histroy list
      this.appendHistroy(res.data.id);

      if (res.data && res.data.formSchema && res.data.formSchema.components) {
        this.loading = false;

        this.formComponent = res.data.formSchema.components;
        console.log("formComponent: ", this.formComponent);
        this.urn = [
          {
            urn:
              res.data.externalId ||
              `${process.env.VUE_APP_PC_BASE_URL}/Share/${this.shareCode}/Models/${res.data.id}/GetShareCodeSvf/bubble.json`,
          },
        ];

        this.updateHistroyResult(res.data.id, this.formComponent, this.urn);

        const info = {
          itemNumber: res.data.name, // 品號放 window 讓其他開發者擷取
          downloadLinks: res.data.downloadLinks, // 模型下載連結
        };
        if (window.parent) {
          window.parent.postMessage(["SYNCOBOX_ITEM_INFO", info], "*");
        }
      } else {
        this.conversion(res);
      }
    },

    conversion(res) {
      this.startWaiting();

      this.getConnectionId().then((connectionId) => {
        axios
          .post(
            `${process.env.VUE_APP_PC_BASE_URL}/ProductModel/${res.data.id}/Connection?connectionId=${connectionId}`
          )
          .then((res) => {
            if (
              res.status === 400 &&
              res.data === "Product Model Processing Finished"
            ) {
              this.stopWaiting();
              this.getMetaData(this.shareCode, this.formResult);
            }

            if (
              res.status === 400 &&
              res.data === "Product Model Processing Failed"
            ) {
              this.stopWaiting();
              this.showError(["該規格品目前無法使用，請聯絡系統管理員。"]);
            }
          })
          .catch((error) => {
            this.stopWaiting();
            this.showError([`[ ERROR ] ${error.response.data}`]);
          });

        this.metadataTimeout = setTimeout(() => {
          this.showError(["該規格品目前無法使用，請聯絡系統管理員。"]);
        }, 60000 * 5);
      });
    },

    showError(msg) {
      this.errorMessages = msg;
      this.error = this.errorMessages.length > 0;
    },

    onFormSubmit(val) {
      console.log("onSubmit: ", val);
      this.formResult = val.data;
      this.getMetaData(this.shareCode, val);
      // TODO: 表單結果結構改變，需要修改
      // if (val.isValid) {
      //   this.formResult = val.data;
      //   this.getMetaData(this.shareCode, val.data);
      // } else {
      //   this.showError(["請確認表單填寫內容是否正確。"]);
      // }
    },

    appendHistroy(modelId) {
      if (this.submitHistroy.find((e) => e.id === modelId)) return;

      this.submitHistroy.push({
        id: modelId,
        formComponent: null,
        urn: null,
      });
    },

    updateHistroyResult(modelId, formComponent, urn) {
      const index = this.submitHistroy.findIndex((e) => e.id === modelId);
      this.submitHistroy[index].formComponent = formComponent;
      this.submitHistroy[index].urn = urn;
    },

    startWaiting() {
      this.loading = false;
      this.waiting = true;
    },

    stopWaiting() {
      this.waiting = false;
    },

    onViewerStart(viewer) {
      // if roomId exists, activate the syncing extension
      if (!this.roomId) return;

      window.Autodesk.Viewing.theExtensionManager.registerExternalExtension(
        "Syncobox.Forge.Extension.Syncing",
        "https://cdn.syncobox.com/extensions/Syncobox.Forge.Extension.Syncing@0.2.1.js"
      );

      viewer.loadExtension("Syncobox.Forge.Extension.Syncing", {
        signalrHost: `${process.env.VUE_APP_PC_BASE_URL}/publicpchub`,
        roomId: this.roomId,
        restoreImmediately: this.restoreImmediately,
        latency: this.latency,
        eventTriggered: this.eventTriggered,
        restoreFPS: this.restoreFPS,
      });
    },
  },
  watch: {
    showForm: {
      immediate: false,
      handler() {
        if (this.showForge) {
          setTimeout(() => {
            window.viewer.resize();
          }, 50);
        }
      },
    },
    showForge: {
      immediate: true,
      handler(show) {
        if (show && !this.forgeActivated) this.forgeActivated = true;
      },
    },
  },
};
</script>
