<template>
  <v-card class="pa-0" width="100%" height="100%" flat :disabled="loading">
    <ForgeViewer
      :id="viewerId"
      :getAccessToken="getAccessToken"
      :urn="urn"
      :autodeskViewingOptions="autodeskViewingOptions"
      :aggregateViewOptions="aggregateViewOptions"
      @viewer-started="onViewerStart"
    />
    <v-overlay
      absolute
      :value="initializing || loading || urn.length == 0"
      :opacity="urn.length == 0 ? 0.72 : 0.46"
    >
      <div
        v-if="urn.length == 0"
        class="d-flex flex-column justify-center align-center"
      >
        <v-img class="mb-8" width="50%" src="@/assets/exclamation.svg"></v-img>
        <h1 style="color: #e1e1e1">此規格品 3D 模型目前無法使用</h1>
        <p>請聯絡系統管理員尋求協助</p>
      </div>
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
        size="64"
        width="7"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import ForgeViewer from "./ForgeVuer.vue";

export default {
  name: "Forge",
  components: { ForgeViewer },
  props: {
    viewerId: {
      type: String,
      default: () => `forge-vuer-${Math.random().toString(36).substring(8)}`,
    },
    getAccessToken: {
      type: Function,
      required: true,
    },
    urn: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    autodeskViewingOptions: () => ({
      env: "AutodeskProduction2",
      api: "streamingV2",
    }),
    aggregateViewOptions: () => ({
      viewerConfig: {
        disableBimWalkInfoIcon: true,
        theme: `light-theme`,
      },
      headlessViewer: false,
    }),
  },
  data: () => ({
    initializing: true,
  }),
  methods: {
    onViewerStart(aggregatedView) {
      this.initializing = false;

      const viewer = aggregatedView.viewer;
      window.viewer = viewer;

      viewer.resize();
      viewer.setTheme("light-theme");
      viewer.addEventListener(
        window.Autodesk.Viewing.EXTENSION_LOADED_EVENT,
        this.unloadUselessExtensions
      );
      viewer.addEventListener(
        window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
        this.onGeomertyLoaded
      );

      this.$emit("viewer-started", viewer);
    },
    unloadUselessExtensions(ext) {
      const ExtList = window.Autodesk.Viewing.AggregatedView.ExtNames;
      delete ExtList.BimWalk;
      const AggregatedViewExts = Object.values(ExtList);
      if (AggregatedViewExts.includes(ext.extensionId)) {
        window.viewer.unloadExtension(ext.extensionId);
      }
    },
    onGeomertyLoaded() {
      window.viewer.fitToView();
    },
  },
};
</script>
